import stFrockLogo from "../assets/images/st-frock-logo.jpg";
import davidLawrenceLogo from "../assets/images/david-lawrence-logo.png";

const LogoCarousel = () => {
    // Normally these would be actual logo images
    const logos = [
        stFrockLogo,
        davidLawrenceLogo,
        stFrockLogo,
        davidLawrenceLogo,
        stFrockLogo,
        davidLawrenceLogo,
      ];
  
    return (
      <div className="bg-gray-50 py-12"> {/* Removed fixed height and changed to padding */}
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">
          Available Retailers
        </h2>
        
        <div className="relative overflow-hidden">
          {/* First row of logos */}
          <div className="flex animate-scroll"> 
            {logos.map((logo, index) => (
              <div
                key={`logo-1-${index}`}
                className="flex-shrink-0 mx-8"
              >                
                <img
                  src={logo}
                  alt={`Client Logo ${index + 1}`}
                  className="h-16 w-auto object-contain"
                />
              </div>
            ))}
            {/* Duplicate set for seamless loop */}
            {logos.map((logo, index) => (
              <div
                key={`logo-2-${index}`}
                className="flex-shrink-0 mx-8"
              >
                <img
                  src={logo}
                  alt={`Client Logo ${index + 1}`}
                  className="h-16 w-auto object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  export default LogoCarousel;