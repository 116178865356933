import { useNavigate } from 'react-router-dom';
import heroImage from '../assets/images/istockphoto-shopping.jpg';

const HeroHeader = () => {
    const navigate = useNavigate();
  
    return (
      <div className="pt-16 bg-white"> {/* Added pt-16 to account for fixed navbar height */}
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center py-12"> {/* Changed min-h-screen to py-12 for better control */}
            {/* Left Column */}
            <div className="space-y-6">
              <p className="text-lg font-semibold text-blue-600">
                A new shopping experience
              </p>
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
                AI Powered Shopping Assistant
              </h1>
              <p className="text-xl text-gray-600">
                Discover your next favourite outfit with our AI powered shopping assistant. Avoid the crowds and shop from the comfort of your home
                while enjoying the expert advice of our AI assistant. Buy your next outfit for work, date night, family gathering, or any other 
                special occasion. Create outfits for any season, style, or budget.
              </p>
              <button
                onClick={() => navigate('/shop')}
                className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Start Shopping
              </button>
            </div>
            
            {/* Right Column */}
            <div className="flex justify-center">
              <div className="w-full max-w-lg">
                <img
                  src={heroImage}
                  alt="Hero"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default HeroHeader;