import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/axiosConfig';
import Navbar from './Navbar';
import { useAuth } from './AuthContext';


// Verification Code Component
export const VerificationCode = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setUserEmail } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
    
        if (!code || code.length !== 6) {
          setError('Please enter a valid 6-digit code');
          return;
        }
    
        setIsLoading(true);
        try {
          const email = sessionStorage.getItem('loginEmail');
          if (!email) {
            navigate('/login');
            return;
          }
    
          // Send verification request to backend
          await api.post('/api/verify/', {
            email,
            code
          });
    
          // Update auth context with user email
          setUserEmail(email);
          
          // Clear session storage
          sessionStorage.removeItem('loginEmail');
          
          // Redirect to dashboard or home page
          navigate('/');
        } catch (err) {
          setError('Invalid verification code. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };
    

    // const handleSubmit = async (e: React.FormEvent) => {
    //   e.preventDefault();
    //   setError('');
  
    //   if (!code || code.length !== 6) {
    //     setError('Please enter a valid 6-digit code');
    //     return;
    //   }
  
    //   setIsLoading(true);
    //   try {
    //     const email = sessionStorage.getItem('loginEmail');
    //     if (!email) {
    //       navigate('/login');
    //       return;
    //     }
  
    //     // Send verification request to backend
    //     await api.post('/auth/verify', {
    //       email,
    //       code
    //     });
  
    //     // Clear session storage
    //     sessionStorage.removeItem('loginEmail');
        
    //     // Redirect to dashboard or home page
    //     navigate('/');
    //   } catch (err) {
    //     setError('Invalid verification code. Please try again.');
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };
  
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/\D/g, '').slice(0, 6);
      setCode(value);
    };
  
    return (
        <>  
            <Navbar />
            <div className="max-w-md w-full mx-auto p-6 bg-white rounded-lg shadow-md mt-16">
                <h2 className="text-2xl font-bold text-center mb-6">Enter Verification Code</h2>
                <p className="text-gray-600 text-center mb-6">
                Please enter the 6-digit code sent to your email
                </p>
        
                <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">
                    Verification Code
                    </label>
                    <input
                    id="code"
                    type="text"
                    value={code}
                    onChange={handleCodeChange}
                    className="w-full px-4 py-2 border rounded-lg text-center text-2xl tracking-widest focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="000000"
                    required
                    />
                </div>
        
                {error && (
                    <div className="text-red-500 text-sm">{error}</div>
                )}
        
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:opacity-50 transition-colors"
                >
                    {isLoading ? 'Verifying...' : 'Sign In'}
                </button>
                </form>
            </div>
        </>
    );
  };
  