// api/axiosConfig.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';
// console.log('API_URL:', API_URL);
const api = axios.create({
    // baseURL: 'https://www.stylewizzard.com',
    baseURL: API_URL, // 'http://localhost:8000/',
    withCredentials: true,  // This is critical for sending cookies with requests
    headers: {
        'Content-Type': 'application/json',
    },
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
});


// Enhanced cookie getter with logging
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
//   console.log(`Getting cookie ${name}:`, cookieValue ? cookieValue.substring(0, 10) + '...' : 'not found');
  return cookieValue;
}

// Set cookie helper
export function setCookie(name, value, days = 7, path = '/') {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}; SameSite=Lax`;
//   console.log(`Set cookie ${name}:`, value.substring(0, 10) + '...');
}

// Save session ID to localStorage as fallback
export function saveSessionToStorage(sessionId) {
  if (sessionId) {
    localStorage.setItem('styleWizzardSession', sessionId);
    // console.log('Saved session to localStorage:', sessionId.substring(0, 10) + '...');
  }
}

// Get session ID from localStorage fallback
export function getSessionFromStorage() {
  const savedSession = localStorage.getItem('styleWizzardSession');
//   console.log('Retrieved session from localStorage:', savedSession ? savedSession.substring(0, 10) + '...' : 'not found');
  return savedSession;
}

// Check if we're in a third-party cookie restricted context
export function checkCookieAccess() {
  // Set a test cookie
  document.cookie = "testCookie=1; path=/; SameSite=Lax";
  
  // Try to read it back
  const cookieEnabled = document.cookie.indexOf("testCookie=") !== -1;
  
  // Report result
//   console.log("Cookie access:", cookieEnabled ? "enabled" : "disabled");
  
  return cookieEnabled;
}

// Add request interceptor to handle both CSRF token and user email
api.interceptors.request.use(async config => {
    // Add CSRF token for mutation requests
    if (['post', 'put', 'patch', 'delete'].includes(config.method?.toLowerCase() ?? '')) {
        const csrfToken = getCookie('csrftoken');
        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
        }
    }

    // Add user email from cookie
    const userEmail = getCookie('userEmail');
    if (userEmail) {
        config.headers['X-User-Email'] = userEmail;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor to log cookies for debugging
api.interceptors.response.use(response => {
    // For debugging - check if Set-Cookie header is present
    const setCookieHeader = response.headers['set-cookie'];
    // if (setCookieHeader) {
    //     console.log('Server sent cookies:', setCookieHeader);
    // }
    return response;
}, error => {
    return Promise.reject(error);
});

export default api;