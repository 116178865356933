import React, { useState, useRef, useEffect } from 'react';
import { Heart, RefreshCw, Send } from 'lucide-react';
import api from '../api/axiosConfig';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getCookie } from '../api/axiosConfig';
import { setCookie } from '../api/axiosConfig';
import config from '../config';

interface Message {
  text: string;
  type: 'user' | 'bot' | 'error';
  metadata?: Record<string, any>;
  isStreaming?: boolean;
}

interface ImageData {
  url: string;
  productName: string;
  productUrl: string;
  isLiked?: boolean;
}

const ChatInterface: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState<ImageData[]>([]);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const navigate = useNavigate();

  // Add this useEffect at the top of your ChatInterface component
  useEffect(() => {
    // Force an initial request to establish a session
    const initializeSession = async () => {
      try {
        // console.log('Initializing session...');
        // console.log('Current cookies:', document.cookie);
        // console.log(`${window.location.origin}`);
        const response = await fetch(`${config.API_URL}/api/chat/`, {
          method: 'GET',
          credentials: 'include',
        });
        
        // console.log('Session init response:', response.status);
        
        if (response.ok) {
          const data = await response.json();
          // console.log('Session init data:', data);
          
          // Check cookies after response
          setTimeout(() => {
            // console.log('Cookies after init:', document.cookie);
            const sessionId = getCookie('sessionid');
            // console.log('Session cookie after init:', sessionId);
            if (sessionId) {
              setSessionId(sessionId);
            }
          }, 100);
        }
      } catch (error) {
        console.error('Error initializing session:', error);
      }
    };
    
    initializeSession();
  }, []);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    
    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, 128);
    textarea.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  const handleLike = async (productName: string, index: number) => {
    try {
      await api.post('/api/like/', {
        product_name: productName
      });
      
      setImageData(prevData => 
        prevData.map((item, i) => 
          i === index ? { ...item, isLiked: !item.isLiked } : item
        )
      );
    } catch (error: any) {
      if (error.response?.status === 401) {
        setShowLoginModal(true);
      } else {
        console.error('Error liking product:', error);
      }
    }
  };

  const handleNewChat = async () => {
    try {
      const response = await api.post('/api/newchat/');
      
      // If the response includes a session_id, update the client-side cookie
      if (response.data?.session_id) {
        // Update the sessionId state
        setSessionId(response.data.session_id);
        
        // Update the sessionid cookie
        setCookie('sessionid', response.data.session_id, 7);
        
        // console.log('Updated session ID from new chat:', response.data.session_id);
      }
      
      // Clear UI state
      setMessages([]);
      setImageData([]);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleStreamingResponse = async () => {
    if (!inputMessage.trim()) return;

    // Add user message
    const userMessage: Message = {
      text: inputMessage,
      type: 'user'
    };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Add a placeholder for the streaming bot response
    setMessages(prevMessages => [
      ...prevMessages, 
      { text: '', type: 'bot', isStreaming: true }
    ]);

    try {
      // Get the sessionId from cookie or state
      const sessionCookie = getCookie('sessionid');
      const effectiveSessionId = sessionCookie || sessionId;
      
      // console.log('Using session ID:', effectiveSessionId ? 
      //   effectiveSessionId.substring(0, 10) + '...' : 'none');
      
      // Create the appropriate headers
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      
      // Get CSRF token
      const csrfToken = getCookie('csrftoken');
      if (csrfToken) {
        headers.append('X-CSRFToken', csrfToken);
      }
      
      // Add user email if available
      const userEmail = getCookie('userEmail');
      if (userEmail) {
        headers.append('X-User-Email', userEmail);
      }

      // Create a POST request
      // console.log(`${config.API_URL}/api/chat/`)
      const response = await fetch(`${config.API_URL}/api/chat/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ 
          query: userMessage.text, 
          streaming: true,
          // Include the session ID in the request body as a fallback
          session_id: effectiveSessionId
        }),
        credentials: 'include', // Include cookies
      });

      // console.log('Response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      if (!response.body) {
        throw new Error('ReadableStream not supported');
      }

      // Set up stream reader
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let streamedText = '';
      let buffer = '';  // Buffer to collect partial SSE messages

      // Process the stream
      const processStream = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            
            if (done) {
              break;
            }
            
            const chunk = decoder.decode(value, { stream: true });
            
            // Add to buffer and process any complete SSE messages
            buffer += chunk;
            
            // Process complete SSE messages in the buffer
            const messages = buffer.split('\n\n');
            // Keep the last item in the buffer if it's incomplete
            buffer = messages.pop() || '';

            // Process each complete message
            for (const message of messages) {
              if (message.startsWith('data: ')) {
                try {
                  // Extract the JSON part (everything after "data: ")
                  const jsonStr = message.substring(6);
                  const eventData = JSON.parse(jsonStr);
                  
                  if (eventData.type === 'token') {
                    // Update streamed text with new token
                    streamedText += eventData.content;
                    
                    // Update the last message with new content
                    setMessages(prevMessages => {
                      const newMessages = [...prevMessages];
                      const lastMessage = newMessages[newMessages.length - 1];
                      if (lastMessage && lastMessage.isStreaming) {
                        lastMessage.text = streamedText;
                      }
                      return newMessages;
                    });
                  } 
                  else if (eventData.type === 'metadata') {
                    // Process metadata (images, products, etc.)
                    const metadata = eventData.content;
                    
                    // Save session ID if present in metadata
                    if (metadata.session_id) {
                      // console.log('Got session ID from metadata:', metadata.session_id);
                      setSessionId(metadata.session_id);
                    }
                    
                    // Update the last message with metadata
                    setMessages(prevMessages => {
                      const newMessages = [...prevMessages];
                      const lastMessage = newMessages[newMessages.length - 1];
                      if (lastMessage && lastMessage.isStreaming) {
                        lastMessage.metadata = metadata;
                        lastMessage.isStreaming = false;
                      }
                      return newMessages;
                    });
                    
                    // Update image data for display
                    if (metadata.images && metadata.products && metadata.urls && metadata.liked) {
                      const combinedImageData = metadata.images.map((url: string, index: number) => ({
                        url,
                        productName: metadata.products[index] || `Product ${index + 1}`,
                        productUrl: metadata.urls[index],
                        isLiked: metadata.liked[index]
                      }));
                      setImageData(combinedImageData);
                    }
                  }
                  else if (eventData.type === 'complete') {
                    // Streaming is complete
                    // console.log('Stream complete');
                    break;
                  }
                } catch (e) {
                  console.error('Error parsing SSE data:', e, 'Raw message:', message);
                }
              }
            }
          }
        } catch (error) {
          console.error('Error reading stream:', error);
          // Handle stream reading error
          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage && lastMessage.isStreaming) {
              lastMessage.type = 'error';
              lastMessage.text = 'Error processing response. Please try again.';
              lastMessage.isStreaming = false;
            }
            return newMessages;
          });
        } finally {
          setIsLoading(false);
        }
      };

      await processStream();
    } catch (error) {
      console.error('Error initiating streaming response:', error);
      // Handle fetch error
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        const lastMessage = newMessages[newMessages.length - 1];
        if (lastMessage && lastMessage.isStreaming) {
          lastMessage.type = 'error';
          lastMessage.text = 'Sorry, something went wrong. Please try again.';
          lastMessage.isStreaming = false;
        }
        return newMessages;
      });
      setIsLoading(false);
    }
  };

  // Use streaming by default
  const handleSendMessage = handleStreamingResponse;

  return (
    <>
      <Navbar />
      <div className="flex h-[calc(100vh-4rem)] mt-16">
        {/* Left Column - Chat Interface */}
        <div className="w-1/2 flex flex-col bg-gray-100">
          <div className="flex-grow overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div 
                key={index} 
                className={`p-3 rounded-lg ${
                  message.type === 'user' 
                    ? 'self-end bg-blue-500 text-white ml-auto' 
                    : message.type === 'bot'
                      ? 'self-start bg-gray-200 text-black mr-auto'
                      : 'self-start bg-red-500 text-white mr-auto'
                } ${message.type === 'bot' ? 'max-w-lg' : 'max-w-xs'}`}
              >
                {message.type === 'bot' ? (
                  <ReactMarkdown className="prose prose-sm max-w-none prose-p:my-1 prose-headings:my-2">
                    {message.text || (message.isStreaming ? '...' : '')}
                  </ReactMarkdown>
                ) : (
                  message.text
                )}
              </div>
            ))}
            {isLoading && !messages[messages.length - 1]?.isStreaming && (
              <div className="self-start bg-gray-200 text-black p-3 rounded-lg">
                Thinking...
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
            
        <div className="flex p-4 bg-white border-t">
          <textarea
            ref={textareaRef}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            placeholder="Type your message..."
            className="flex-grow p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none overflow-y-auto"
            disabled={isLoading}
            style={{ 
              maxHeight: '48px' 
            }}
          />
          <button 
            onClick={handleSendMessage} 
            disabled={isLoading || !inputMessage.trim()}
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 disabled:opacity-50 border border-blue-500 flex items-center justify-center"
            title="Send message"
          >
            <Send className="w-5 h-5" />
          </button>
          <button 
            onClick={handleNewChat}
            disabled={isLoading}
            className="bg-gray-500 text-white px-4 py-2 rounded-r-lg hover:bg-gray-600 disabled:opacity-50 border border-gray-500 flex items-center justify-center"
            title="Start a new chat"
          >
            <RefreshCw className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Right Column - Image Display */}
      <div className="w-1/2 bg-gray-200 p-4 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Products Gallery</h2>
        {imageData.length > 0 ? (
          <div className="grid grid-cols-2 gap-4">
            {imageData.map((image, index) => (
              <div 
                key={index} 
                className="bg-white p-2 rounded-lg shadow-md relative"
              >
                <div 
                  className="cursor-pointer hover:opacity-80 transition-opacity"
                  onClick={() => setSelectedImage(image)}
                >
                  <img 
                    src={image.url} 
                    alt={`Model image for ${image.productName}`} 
                    className="w-full h-48 object-cover rounded mb-2"
                  />
                </div>
                <div className="px-2">
                  <div className="font-medium text-sm truncate mb-2">
                    {image.productName}
                  </div>
                  <div className="flex justify-between items-center">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(image.productName, index);
                      }}
                      className="p-1 rounded-full hover:bg-gray-100"
                    >
                      <Heart 
                        className={`w-6 h-6 transition-colors ${
                          image.isLiked 
                            ? 'fill-red-500 stroke-red-500' 
                            : 'stroke-gray-500 hover:stroke-red-500'
                        }`}
                      />
                    </button>
                    <button 
                      onClick={() => window.open(image.productUrl, '_blank')}
                      className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-500 text-center py-10">
            No Products to Display
          </div>
        )}
      </div>

      {/* Full Image Modal */}
      {selectedImage && (
      <div 
        className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
        onClick={() => setSelectedImage(null)}
      >
        <div className="relative max-h-screen flex flex-col items-center justify-center p-4">
          <div className="text-white text-2xl mb-4 text-center">
            {selectedImage.productName}
          </div>
          <img 
            src={selectedImage.url} 
            alt={`Full resolution of ${selectedImage.productName}`} 
            className="max-h-[80vh] w-auto object-contain"
            onClick={(e) => e.stopPropagation()}
          />
          <div className="mt-4 flex items-center gap-4">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                const index = imageData.findIndex(img => img.url === selectedImage.url);
                if (index !== -1) {
                  handleLike(selectedImage.productName, index);
                }
              }}
              className="p-2 rounded-full bg-white/10 hover:bg-white/20"
            >
              <Heart 
                className={`w-8 h-8 transition-colors ${
                  imageData.find(img => img.url === selectedImage.url)?.isLiked
                    ? 'fill-red-500 stroke-red-500' 
                    : 'stroke-white hover:stroke-red-500'
                }`}
              />
            </button>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                window.open(selectedImage.productUrl, '_blank');
              }}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Buy
            </button>
          </div>
          <div 
            className="absolute top-4 right-4 text-white text-4xl font-bold cursor-pointer hover:text-gray-300"
            onClick={() => setSelectedImage(null)}
          >
            ×
          </div>
        </div>
      </div>
    )}

    {showLoginModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div 
          className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4"
          onClick={e => e.stopPropagation()}
        >
          <h2 className="text-xl font-semibold mb-4">Please Log In</h2>
          <p className="text-gray-600 mb-6">
            You need to be logged in to like products. Use your email address to login; we will send you
            a 6-digit code to verify your email address. There is no need to setup an account with password.
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowLoginModal(false)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    )}
    </div>
    </>
  );
};

export default ChatInterface;