// Navbar.tsx
import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import api from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Heart } from 'lucide-react';
import LikedProductsModal from './LikedProductsModal';

const Navbar = () => {
  const { userEmail, setUserEmail } = useAuth();
  const navigate = useNavigate();
  const [isLikedModalOpen, setIsLikedModalOpen] = useState(false);

  const handleLogout = async () => {
      try {
          await api.post('/api/logout/');
          setUserEmail(null);
          navigate('/');
      } catch (error) {
          console.error('Logout failed:', error);
          // Handle error appropriately
      }
  };

  const openLikedProductsModal = () => {
    setIsLikedModalOpen(true);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-between items-center h-16">
            {/* Left side - Home link */}
            <div className="flex items-center">
              <a 
                href="/" 
                className="text-gray-800 hover:text-blue-600 text-lg font-semibold transition-colors"
              >
                Style Wizzard
              </a>
            </div>

            {/* Right side - Auth buttons or user email */}
            <div className="flex items-center mt-0">
              {userEmail ? (
                <div className="flex flex-wrap items-center space-x-3">
                  <span className="text-gray-600 text-sm">{userEmail}</span>
                  
                  {/* Liked Clothes button - only visible when logged in */}
                  <button 
                    onClick={openLikedProductsModal} 
                    className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors flex items-center"
                  >
                    <Heart className="w-4 h-4 mr-1 stroke-current" /> Liked Clothes
                  </button>
                  
                  <button 
                    onClick={handleLogout} 
                    className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={() => navigate('/login')}
                    className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Login
                  </button>                
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Render the LikedProductsModal */}
      <LikedProductsModal 
        isOpen={isLikedModalOpen} 
        onClose={() => setIsLikedModalOpen(false)} 
      />
    </>
  );
};

export default Navbar;