import React from 'react';

interface TestimonialProps {
  quote: string;
  image: string;
  name: string;
}

const Testimonial: React.FC<TestimonialProps> = ({ quote, image, name }) => {
  return (
    <div className="flex flex-col items-center p-6 space-y-4">
      <blockquote className="text-lg text-gray-600 text-center italic">
        "{quote}"
      </blockquote>
      {/* <img
        src={image}
        alt={`${name}'s testimonial`}
        className="w-20 h-20 rounded-full object-cover shadow-lg"
      /> */}
      <p className="font-semibold text-gray-800">{name}</p>
    </div>
  );
};

interface Testimonial {
  quote: string;
  image: string;
  name: string;
}

const TestimonialsSection: React.FC = () => {
  const testimonials: Testimonial[] = [
    {
      quote: "Style Wizzard helped me find the perfect date night dress in minutes - something I'd normally spend hours searching for online!",
      image: "/api/placeholder/80/80",
      name: "Sarah D."
    },
    {
      quote: "I described my dream work wardrobe and the Style Wizzard matched me with pieces that fit perfectly within my budget. It's like having a personal stylist in my pocket!",
      image: "/api/placeholder/80/80",
      name: "Lisa E."
    },
    {
      quote: "Finally found jeans that actually fit thanks to the Style Wizzard's recommendations! Shopping from home has never been this easy.",
      image: "/api/placeholder/80/80",
      name: "Emily R."
    }
  ];

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">
          What Our Customers Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <Testimonial
              key={index}
              quote={testimonial.quote}
              image={testimonial.image}
              name={testimonial.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;