import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatInterface from './components/ChatInterface';
import HeroHeader from './components/HeroHeader';
import LogoCarousel from './components/LogoCarousel';
import TestimonialsSection from './components/Testimonials';
import Navbar from './components/Navbar';
import { EmailLogin } from './components/Login';
import { VerificationCode } from './components/Verify';
import { AuthProvider } from './components/AuthContext';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <div className="min-h-screen flex flex-col">
    <Helmet>
        <title>Style Wizzard</title>
        <meta name="description" content="AI-powered shopping assistant for women's clothing and fashion." />
        <meta name="keywords" content="shopping, assistant, clothing, fashion, accessories, AI, chatbot" />
        <meta property="og:title" content="Style Wizzard" />
        <meta property="og:description" content="AI-powered shopping assistant for women's clothing and fashion." />
        {/* <meta property="og:image" content="https://www.stylewizzard.com/image.jpg" /> */}
        <link rel="canonical" href="https://www.stylewizzard.com" />
      </Helmet>

      <Navbar />
      <div className="flex-grow">
        <HeroHeader />
        <LogoCarousel />
        <TestimonialsSection />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shop" element={<ChatInterface />} />
          <Route path="/login" element={<EmailLogin />} />
          <Route path="/verify" element={<VerificationCode />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;