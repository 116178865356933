// LikedProductsModal.tsx
import React, { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import api from '../api/axiosConfig';

interface LikedProduct {
  name: string;
  url: string;
  image: string;
  isLiked?: boolean;
}

interface LikedProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LikedProductsModal: React.FC<LikedProductsModalProps> = ({ isOpen, onClose }) => {
  const [likedProducts, setLikedProducts] = useState<LikedProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<LikedProduct | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      fetchLikedProducts();
      
      // Add event listener for the Escape key
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          if (selectedProduct) {
            setSelectedProduct(null);
          } else {
            onClose();
          }
        }
      };
      
      window.addEventListener('keydown', handleKeyDown);
      
      // Clean up event listener
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose, selectedProduct]);

  const fetchLikedProducts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get('/api/liked/');
      
      if (response.data && response.data.liked_products) {
        // Ensure all products have isLiked set to true initially
        const products = response.data.liked_products.map((product: LikedProduct) => ({
          ...product,
          isLiked: true
        }));
        
        setLikedProducts(products);
      } else {
        setLikedProducts([]);
      }
    } catch (error) {
      console.error('Error fetching liked products:', error);
      setError('Failed to load your liked products. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = async (productName: string, index: number) => {
    try {
      await api.post('/api/like/', {
        product_name: productName
      });
      
      // After unliking, remove the product from the list
      setLikedProducts(prevProducts => 
        prevProducts.filter((_, i) => i !== index)
      );
    } catch (error) {
      console.error('Error unliking product:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg shadow-xl max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b p-4">
          <h2 className="text-xl font-bold">My Liked Clothes</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-2xl font-bold"
          >
            &times;
          </button>
        </div>
        
        <div className="flex-grow p-4 overflow-y-auto">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <p className="text-gray-500">Loading your favorites...</p>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center p-4">{error}</div>
          ) : likedProducts.length === 0 ? (
            <div className="text-gray-500 text-center p-4">
              You haven't liked any clothes yet.
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {likedProducts.map((product, index) => (
                <div 
                  key={index} 
                  className="bg-white p-2 rounded-lg shadow-md relative border"
                >
                  <div 
                    className="cursor-pointer hover:opacity-80 transition-opacity"
                    onClick={() => setSelectedProduct(product)}
                  >
                    <img 
                      src={product.image} 
                      alt={`Image of ${product.name}`} 
                      className="w-full h-48 object-cover rounded mb-2"
                    />
                  </div>
                  <div className="px-2">
                    <div className="font-medium text-sm truncate mb-2">
                      {product.name}
                    </div>
                    <div className="flex justify-between items-center">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLike(product.name, index);
                        }}
                        className="p-1 rounded-full hover:bg-gray-100"
                      >
                        <Heart 
                          className="w-6 h-6 fill-red-500 stroke-red-500"
                        />
                      </button>
                      <button 
                        onClick={() => window.open(product.url, '_blank')}
                        className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 transition-colors"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      
      {/* Full Image Modal */}
      {selectedProduct && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-[60] flex items-center justify-center"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click from closing the parent modal
            setSelectedProduct(null);
          }}
        >
          <div className="relative max-h-screen flex flex-col items-center justify-center p-4">
            <div className="text-white text-2xl mb-4 text-center">
              {selectedProduct.name}
            </div>
            <img 
              src={selectedProduct.image} 
              alt={`Full resolution of ${selectedProduct.name}`} 
              className="max-h-[80vh] w-auto object-contain"
              onClick={(e) => e.stopPropagation()}
            />
            <div className="mt-4 flex items-center gap-4">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  const index = likedProducts.findIndex(p => p.image === selectedProduct.image);
                  if (index !== -1) {
                    handleLike(selectedProduct.name, index);
                    setSelectedProduct(null);
                  }
                }}
                className="p-2 rounded-full bg-white/10 hover:bg-white/20"
              >
                <Heart className="w-8 h-8 fill-red-500 stroke-red-500" />
              </button>
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(selectedProduct.url, '_blank');
                }}
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Buy
              </button>
            </div>
            <div 
              className="absolute top-4 right-4 text-white text-4xl font-bold cursor-pointer hover:text-gray-300"
              onClick={() => setSelectedProduct(null)}
            >
              ×
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LikedProductsModal;